.podium-container {
    /* margin: 40px 0 60px; */
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 250px;
    width: 100%;
    margin: 0;
    margin-top: 100px;
  }
  .podium-container{
    @media (max-width: 768px) {
     display: none;
    }
  }
  
  .podium-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Podium blocks */
  .podium-block {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    width: 120px;
  }
  
  .podium-block-first {
    height: 190px;
    background-color: #a77b2f;
  }
  
  .podium-block-second {
    height: 140px;
    background-color: #c0c0c0;
  }
  
  .podium-block-third {
    height: 100px;
    background-color: #cd7f32;
  }
  
  .podium-first {
    margin: 0px;
    margin-left: -2px;
    margin-right: -2px;
  }
  
  .podium-number {
    font-size: 48px;
    font-weight: bold;
  }
  
  .podium-block-first .podium-number {
    color: rgba(255, 215, 0, 0.7); 
  }
  
  .podium-block-second .podium-number {
    color: rgba(192, 192, 192, 0.7);
  }
  
  .podium-block-third .podium-number {
    color: rgba(205, 127, 50, 0.7); 
  }
  
  /* Winner profiles */
  .winner-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: -95px;
  }
  
  .avatar-container {
    position: relative;
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
  }
  
  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #cccccc;
  }
  
  .podium-first .avatar-image {
    border-color: #a77b2f;
  }
  
  .podium-second .avatar-image {
    border-color: #c0c0c0;
  }
  
  .podium-third .avatar-image {
    border-color: #cd7f32;
  }
  
  .rank-badge {
    position: absolute;
    top: -10px;
    right: -5px;
    background-color: #ffc107;
    color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
  }
  
  .winner-info {
    text-align: center;
    margin-top: -15px;
  }
  
  .winner-name {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 2px 0;
  }
  
  .winner-id {
    font-size: 12px;
    color: #666;
    margin: 0;
  }
  
  /* Score badges */
  .score-badge {
    position: absolute;
    bottom: -12px;
    background-color: white;
    border: 1px solid #e0e0e0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .podium-block {
      width: 90px;
    }
    
    .podium-block-first {
      height: 150px;
    }
    
    .podium-block-second {
      height: 110px;
    }
    
    .podium-block-third {
      height: 80px;
    }
    
    .podium-number {
      font-size: 36px;
    }
    
    .avatar-container {
      width: 50px;
      height: 50px;
    }
    
    .winner-name, .winner-id {
      font-size: 12px;
    }
  }