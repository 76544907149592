$blue : #021827;
$primary: #021827;
$secondary: #2C3E4B;
$ter : #062324;

.leader-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.7rem;
    background-color: #f8f9fa;
    border-radius: 16px;
    margin-bottom: 16px;
    font-family: "HelveticaNeue" !important;


    .tabs {
        display: flex;
        gap: 10px;
        background-color: #E8ECFF;
        padding: 4px;
        border-radius: 8px;
    }

    button.tab {
        padding: 4px 9px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        background: transparent;
        font-weight: bold;
        color: #333;
        border-radius: 4px;
        transition: all 0.3s ease;
        font-weight: 400;
        font-size: 1rem;


        &.active {
            background: #f8f8f8;
            color: $blue;
            font-weight: 700;
        }
    }

    .tab.active {
        background: #007bff;
        color: white;
    }

    .last-updated {
        font-size: 14px;
        color: $blue;
        margin-bottom: 0;

        span {
            font-weight: bold;
        }
    }

    @media (max-width: 768px) {
        .tabs-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .tabs {
            flex-direction: column;
            width: 100%;
        }

        .tab {
            width: 100%;
            text-align: left;
        }
    }
}


.leader-cards {
    display: flex;
    row-gap: 1rem;
    column-gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .stat-card {
        background-color: white;
        padding: 12px 18px;
        flex-basis: 24%;
        border-radius: 16px;
        position: relative;


        svg {
            margin-bottom: 8px;
        }

        h3 {

            &.stat-title {
                color: $secondary;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 8px;
            }

            &.stat-count {
                color: $blue;
                font-size: 20px;
                font-weight: 700;
            }
        }

        .leader-bg {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.leader-user-tracker {
    width: 100%;
    background-color: white;
    min-height: 70vh;
    padding: 48px 24px;
    border-radius: 8px;

    figure.profile-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        svg {
            width: 100px;
            height: 100px;
        }
    }

    figure.profile-image img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    
    .profile-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            color: $primary;
            font-size: 18px;
            font-weight: 700;
            margin: 0;
        }

        p {
            color: $secondary;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
        }
    }

    .profile-stats {
        display: flex;
        align-items: center;
        margin-top: 12px;

        .svg-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #C3C8E4;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        span {
            color: #021827;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            padding-left: 8px;
        }
    }

    .leader-badges {
        display: flex;
        gap: 10px;
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: center;

        .leader-badge {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-basis: 20%;
            flex-shrink: 0;
            flex-grow: 0;

            p {
                color: #021827;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                margin: 0;
                padding-left: 8px;
                text-align: center;
                margin-top: 8px;
            }
        }
    }



    .score-progress {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            color: #021827;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            margin: 0;
        }
    }

    .badges-bar {
        display: flex;
        gap: 4px;

        .badge-bar {
            width: 8px;
            height: 16px;
            border-radius: 2px;
            background-color: #E0FBE2;

            &.active {
                background-color: #197D21;
            }
        }
    }

}


.alc-details {
    margin-top: 2rem;

    h2 {
        color: $ter;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    h4 {
        color: $ter;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 1.2rem;



        span {
            color: $ter;
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            margin: 0;

        }
    }
}

.welcome-component {
    background-color: #FFF7E9;
    border: solid 1px #F9DCAD;
    border-radius: 16px;
    height: 148px;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
        color: #021827;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        margin: 0;
    }

    h6 {
        color: #021827;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        margin: 0;
        margin-top: 8px;
    }

    button {
        background: #fff;
        border: solid 1px #00000080;
        border-radius: 8px;
        padding: 8px 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #7117E5;
        text-align: center;
        margin-top: 24px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
        padding: 20px;
        align-items: start;
    }
}

.welcome-content {
    flex: 1;

    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
}

.welcome-image {
    @media (max-width: 768px) {
        display: none;
    }
}


.peer-guidelines {
    width: 100%;
    background-color: white;
    min-height: 70vh;
    padding: 48px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;


    h2 {
        color: #2C3E4B;
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 32px;
    }

    .guideline {
        display: flex;
        margin-bottom: 1.2rem;
        filter: blur(5px) brightness(90%); 
        transition: filter 0.3s ease; 

        &.active {
            filter: blur(0) brightness(100%);
        }


        .svg-icon {
            margin-right: 1rem;
        }


        h4 {
            color: #2C3E4B;
            font-size: 16px;
            line-height: 18px;
            font-weight: 700;
            margin: 0;
        margin-bottom: .5rem;

        }

        p {
            color: #2C3E4B;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            margin: 0;
        }
    } 

}


.empty-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;



    figure {
        img {
            width:100%;
        }
    }
}