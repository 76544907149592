$blue : #021827;
$primary: #021827;
$secondary: #2C3E4B;

.leader-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.7rem;
    background-color: #f8f9fa;
    border-radius: 16px;
    margin-bottom: 16px;

    .tabs {
        display: flex;
        gap: 10px;
        background-color: #E8ECFF;
        padding: 4px;
        border-radius: 8px;
    }

    button.tab {
        padding: 4px 9px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        background: transparent;
        font-weight: bold;
        color: #333;
        border-radius: 4px;
        transition: all 0.3s ease;
        font-weight: 400;
        font-size: 1rem;


        &.active {
            background: #f8f8f8;
            color: $blue;
            font-weight: 700;
        }
    }

    .tab.active {
        background: #007bff;
        color: white;
    }

    .last-updated {
        font-size: 14px;
        color: $blue;
        margin-bottom: 0;

        span {
            font-weight: bold;
        }
    }

    // Mobile responsiveness: Stack the tabs on mobile
    @media (max-width: 768px) {
        .tabs-container {
            flex-direction: column;
            align-items: flex-start;
        }

        .tabs {
            flex-direction: column;
            width: 100%;
        }

        .tab {
            width: 100%;
            text-align: left;
        }
    }
}


.leader-cards {
    display: flex;
    row-gap: 1rem;
    column-gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .stat-card {
        background-color: white;
        padding: 12px 18px;
        flex-basis: 24%;
        border-radius: 16px;
        position: relative;


        svg {
            margin-bottom: 8px;
        }

        h3 {

            &.stat-title {
                color: $secondary;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 8px;
            }

            &.stat-count {
                color: $blue;
                font-size: 20px;
                font-weight: 700;
            }
        }

        .leader-bg {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    @media (max-width: 768px) {
        .leader-cards {
            flex-direction: column;
            align-items: flex-start;
            background-color: red;
        }
    }
}
@media (max-width: 768px) {
    .leader-cards {
        flex-direction: column;
    }
}


.leader-user-tracker {
    width: 100%;
    background-color: white;
    min-height: 70vh;
    padding: 48px 24px;
    border-radius: 8px;

    figure.profile-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    figure.profile-image img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }


    .profile-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            color: $primary;
            font-size: 18px;
            font-weight: 700;
            margin:0;
        }

        p {
            color: $secondary;
            font-size: 14px;
            font-weight: 400;
            margin:0;
        }
    }

    .profile-stats {
        display: flex;
        align-items: center;
        margin-top:12px;

        .svg-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #C3C8E4;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        span {
            color:#021827;
            font-size: 14px;
            font-weight: 400;
            margin:0;
            padding-left:8px;
        }
    }

    .leader-badges {
        display: flex;
        gap: 10px;
        margin-top: 20px;
        flex-wrap: wrap;

        .leader-badge {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-basis: 25%;
            flex-shrink: 0;
            flex-grow: 0;

            p  {
                color:#021827;
                font-size: 14px;
                line-height: 16px;
                font-weight: 400;
                margin:0;
                padding-left:8px;
                // width: 50%;
                text-align: center;
                margin-top:8px;
            }
        }
    }



    .score-progress {
        margin-top: 20px;
    }

    .score-bar {
        background: #f0f0f0;
        height: 10px;
        border-radius: 5px;
    }

    .score-bar-filled {
        background: #4caf50;
        height: 100%;
        border-radius: 5px;
    }
}


.group-stat-cards {
    display: flex;
    row-gap: 1rem;
    column-gap: 20px;
    margin-bottom: 16px;
    margin-top: 16px;

    .peer-group-card {
        background-color: white;
        padding: 12px 18px;
        // flex-basis: 50%;
        flex: 1;
        border-radius: 16px;
        position: relative;


        svg {
            margin-bottom: 8px;
        }

        h3 {

            &.stat-title {
                color: $secondary;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 8px;
            }

            &.stat-count {
                color: $blue;
                font-size: 20px;
                font-weight: 700;
            }
        }

        .leader-bg {
            position: absolute;
            top: 0;
            right: 0;
        }
        .leader-bg-bottom {
            position: absolute;
            bottom: -7px;
            right: 0;
        }
    }
   
}